<script setup>
import { computed, ref } from 'vue'
import { success, error } from '@/utils'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const { t } = useI18n()
const store = useStore()

const activeCoupons = computed(() => store.getters['subscription/activeCoupons'])
const promoCode = ref('')
const loading = ref(false)

const handleSubmit = () => {
  if(!promoCode.value) {
    return
  }
  loading.value = true
  store.dispatch('subscription/redeemPromotionCode', promoCode.value).then(()=>{
    store.dispatch('subscription/getActiveCoupons')
    loading.value = false
    success(t('components.subscriptionSettingsPromoCode.successMessage'))
  }).catch(()=>{
    loading.value = false
    error(t('components.subscriptionSettingsPromoCode.errorMessage'))
  })
}
</script>

<template>
  <a-form
    v-if="activeCoupons?.length === 0"
    @submit="handleSubmit"
  >
    <div style="display: flex; gap: 8px; align-items: center; margin: 4px 0; white-space: nowrap;">
      <a-typography-text>Promo Code:</a-typography-text>
      <a-input-group compact>
        <a-input
          v-model:value="promoCode"
          :loading="loading"
          style="width: 150px;"
          :placeholder="$t('components.subscriptionSettingsPromoCode.promoCodePlaceholder')"
          @keydown.enter.prevent="handleSubmit"
        />
        <a-button
          type="primary"
          :loading="loading"
          @click="handleSubmit"
        >
          {{ $t('components.subscriptionSettingsPromoCode.applyButtonText') }}
        </a-button>
      </a-input-group>
    </div>
  </a-form>
</template>

<style scoped lang="less">

</style>
